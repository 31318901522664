.fullwidth {
  background-color: #03a5b6;
  width: 100%;
  display: flex;
  justify-content: center;
}

.teal {
  background-color: #03a5b6;
  width: 90%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.videoplayer {
  min-width: 475px;
}

.marketing-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-top: 0px;
  max-width: 800px;
  text-align: left;
}

.marketing-h1 {
  font-family: modak;
  color: #ffb200;
  text-shadow: 1px 1px black;
}

.marketing-p {
  font-family: alegreya;
  color: white;
  font-size: 1.3rem;
  text-shadow: 1px 1px black;
}

@media (min-width: 800px) {
  .marketing-section {
    display: flex;
  }
}

@media (max-width: 799px) {
  .marketing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .marketing-text {
    max-width: 350px;
  }
  
  .videoplayer {
    min-width: 475px;
  }
}

@media (max-width: 600px) {
  .videoplayer {
    min-width: 275px;
  }
}